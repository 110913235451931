<template>
  <div>
    <div class="px-3">
      <loader-info v-if="isLoading" />
      <document-item
        v-for="doc in documentsList"
        :key="doc.documentId"
        :current-doc="doc"
        :entity-id="entityId"
        :entity-class="entityClass"
        :on-success="onSuccessUpload"
      />
    </div>
  </div>
</template>

<script>
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const DocumentItem = () => import('@/components/documents/DocumentItem.vue');

export default {
  name: 'CompanyDocumentsList',
  components: { DocumentItem, LoaderInfo },
  props: {
    entityClass: String,
    entityId: String,
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    isLoading() {
      return this.$store.getters['documents/isLoading'];
    },
    documentsList() {
      const docs = this.$store.getters['documents/getDocumentList'](this.entityClass, this.entityId);
      console.log('documentsList', docs);
      return docs.filter((doc) => {
        const { documentInfo } = doc;
        const { documentEntityType, documentType } = documentInfo;
        if (documentEntityType === 'PERSON') {
          if (documentType === 'IDENTITY') {
            return false;
          }
        }
        return true;
      });
    },
  },
  methods: {
    fetchData() {
      this.$store.dispatch('documents/loadDocuments',
        { entityClass: this.entityClass, entityId: this.entityId })
        .then((data) => {
          console.log('Response', data);
        },
        (error) => {
          console.log('error', error);
        });
    },
    onSuccessUpload() {
      if (!this.$store.getters['auth/isLogged']) {
        return;
      }

      this.$store.dispatch('subject/loadSubject');
    },
    onSuccessAdd(data) {
      this.$store.dispatch('documents/setDocuments', {
        entityClass: this.entityType,
        entityId: this.entityId,
        docs: data,
      });
    },
  },
};
</script>

<style scoped>

</style>
